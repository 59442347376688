export const manualOr = [
  { contractStatusType: 'New' },
  { contractStatusType: 'Manual' },
  { contractStatusType: 'Open' },
  { contractStatusType: 'Automatic' },
]

export const manualFilter = {
  or: manualOr,
}

export const completedOr = [
  { contractStatusType: 'Closed' },
  { contractStatusType: 'Finished' },
  { contractStatusType: 'Anonymized' },
  { contractStatusType: 'Dismissed' },
  { contractStatusType: 'Rejected' },
  { contractStatusType: 'Undefined' },
]

export const completedFilter = {
  or: completedOr,
}

export const completedExpand = {
  Editors: { orderBy: 'Edited desc', top: 1 },
}
