import React from 'react'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { cilUser, cilUserFemale } from '@coreui/icons'
import { BuildHeader } from 'src/Utils/headerBuilder'
import { RequestMethods } from '../Helper/FetchHelpers'
import { format, getHours } from 'date-fns'
import { OdataTimeFormat } from 'src/Utils/TimeFormats'
import { CreationPointTypeEnum, CustomerTypeEnum, GetEnumsList } from 'src/Utils/EnumMapper'

const initialState = () => {
  return {
    ageSpan: [],
    contractTrend: [],
    creationPoint: [],
    zipCodes: [],
    gender: [],
    customerTypes: [],
  }
}
export const GetWidgetAgeSpan = createAsyncThunk('WidgetAgeSpan', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + `/persons` + `/ageRange`,
    requestOptions,
  )

  const formattedResponse = await response.json()
  if (formattedResponse.status === 1) {
    throw new Error(formattedResponse.message)
  }
  const total = _.sumBy(formattedResponse, 'value')
  return formattedResponse.map((item) => {
    return { title: item.key, value: item.value, percent: (item.value / total) * 100 }
  })
})

export const GetWidgetContractTends = createAsyncThunk('WidgetContractTends', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT +
      `/contract` +
      `?$apply=filter(created gt ${format(
        new Date(2022, 9, 11, getHours(new Date())),
        OdataTimeFormat,
      )})/compute(hour(created) as hour)/groupby((hour), aggregate($count as count))`,
    requestOptions,
  )

  const formattedResponse = await response.json()
  if (formattedResponse.status === 1) {
    throw new Error(formattedResponse.message)
  }
  const yearOrder = _.orderBy(formattedResponse, ['hour'], ['asc'])
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23].map(
    (h) => {
      const count = yearOrder.find((x) => x.hour === h)

      if (count === undefined) return 0
      return count.count
    },
  )
})

export const GetWidgetCreationPoint = createAsyncThunk('WidgetCreationPoint', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT +
      `/contract` +
      `?$apply=groupby((CreationPoint), aggregate($count as count))`,
    requestOptions,
  )

  const formattedResponse = await response.json()
  if (formattedResponse.status === 1) {
    throw new Error(formattedResponse.message)
  }
  const creationPoint = GetEnumsList(CreationPointTypeEnum)
  const total = _.sumBy(formattedResponse, 'count')
  return formattedResponse.map((x) => {
    return {
      title: creationPoint.find((p) => p.value == x.creationPoint)?.label,
      percent: (x.count / total) * 100,
      value: x.count,
    }
  })
})

export const GetWidgetZipCode = createAsyncThunk('WidgetZipCode', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT +
      `/contract` +
      `?$apply=groupby((deliveryAddress/postalCode,deliveryAddress/city), aggregate($count as count))`,
    requestOptions,
  )

  const formattedResponse = await response.json()

  // if (formattedResponse.status === 1) {
  //   throw new Error(formattedResponse.message)
  // }

  let cities = formattedResponse.map((x) => {
    return {
      city: x.deliveryAddress.city === null ? 'Ikke kendt' : x.deliveryAddress.city,
      count: x.count,
      postalCode: x.deliveryAddress.postalCode === null ? '0000' : x.deliveryAddress.postalCode,
    }
  })

  const total = _.sumBy(cities, 'count')

  return cities.map((x) => {
    return {
      title: x.city,
      value: x.count,
      postalCode: x.postalCode,
      percent: (x.count / total) * 100,
    }
  })
})

export const GetWidgetGender = createAsyncThunk('WidgetGender', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT +
      `/persons` +
      `?$apply=groupby((GenderTypeId), aggregate($count as count))`,
    requestOptions,
  )
  const formattedResponse = await response.json()
  if (formattedResponse.status === 1) {
    throw new Error(formattedResponse.message)
  }
  return formattedResponse
})

export const GetWidgetCustomerType = createAsyncThunk('WidgetCustomerType', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT +
      `/contract` +
      `?$apply=groupby((CustomerType), aggregate($count as count))`,
    requestOptions,
  )

  const formattedResponse = await response.json()
  if (formattedResponse.status === 1) {
    throw new Error(formattedResponse.message)
  }

  const CustomerType = GetEnumsList(CustomerTypeEnum)
  const total = _.sumBy(formattedResponse, 'count')

  return formattedResponse.map((x) => {
    return {
      title: CustomerType.find((p) => p.value == x.customerType)?.label,
      percent: (x.count / total) * 100,
      value: x.count,
    }
  })
})

export const GetWidgetContractsCount = createAsyncThunk('WidgetContractsCount', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + `contractscount`,
    requestOptions,
  )

  const formattedResponse = await response.text()
  // if (formattedResponse.status === 1) {
  //   throw new Error(formattedResponse.message)
  // }

  return formattedResponse
  // const CustomerType = GetEnumsList(CustomerTypeEnum)
  // const total = _.sumBy(formattedResponse.value, 'count')

  // return formattedResponse.value.map((x) => {
  //   return {
  //     title: CustomerType.find((p) => p.value === x.CustomerTypeId)?.label,
  //     percent: (x.count / total) * 100,
  //     value: x.count,
  //   }
  // })
})

const metricSlice = createSlice({
  name: 'Metric',
  initialState,
  reducers: {},
  extraReducers: {
    [GetWidgetAgeSpan.pending]: (state) => {
      state.isLoading = true
    },
    [GetWidgetAgeSpan.fulfilled]: (state, action) => {
      state.ageSpan = action.payload
    },
    [GetWidgetAgeSpan.rejected]: (state, action) => {},

    [GetWidgetContractTends.pending]: (state) => {
      state.isLoading = true
    },
    [GetWidgetContractTends.fulfilled]: (state, action) => {
      state.contractTrend = action.payload
    },
    [GetWidgetContractTends.rejected]: (state, action) => {},

    [GetWidgetCreationPoint.pending]: (state) => {
      state.isLoading = true
    },
    [GetWidgetCreationPoint.fulfilled]: (state, action) => {
      state.creationPoint = action.payload
    },
    [GetWidgetCreationPoint.rejected]: (state, action) => {},

    [GetWidgetZipCode.pending]: (state) => {
      state.isLoading = true
    },
    [GetWidgetZipCode.fulfilled]: (state, action) => {
      state.zipCodes = action.payload
    },
    [GetWidgetZipCode.rejected]: (state, action) => {},

    [GetWidgetGender.pending]: (state) => {
      state.isLoading = true
    },
    [GetWidgetGender.fulfilled]: (state, action) => {
      state.gender = action.payload
    },
    [GetWidgetGender.rejected]: (state, action) => {},

    [GetWidgetCustomerType.pending]: (state) => {
      state.isLoading = true
    },
    [GetWidgetCustomerType.fulfilled]: (state, action) => {
      state.customerTypes = action.payload
    },
    [GetWidgetCustomerType.rejected]: (state, action) => {},
  },
})

export const { UpdateStates } = metricSlice.actions

export default metricSlice.reducer

export const State = {
  play: 1,
  stop: 2,
  //   pause: 3,
}

export const ServiceStatus = {
  done: 0,
  working: 1,
}
