import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _, { map } from 'lodash'
import { AsyncFormRequest, AsyncOdataObjectQuery, AsyncQueryRequest } from 'src/Utils/headerBuilder'

import { RequestMethods } from '../Helper/FetchHelpers'

const apiController = '/Ticket'

const initialState = () => {
  return {
    Observers: [],
    TicketsActive: 0,
    Tickets: [],
    Messages: [],
    Updated: 0,
  }
}

const GetTicketQuery = {
  count: true,
  expand: {
    messages: {
      expand: {
        files: { select: ['id', 'name', 'suffix', 'description', 'bytesize', 'attacment'] },
      },
    },
  },
}

export const GetTikket = createAsyncThunk('GetTickets', async () =>
  AsyncOdataObjectQuery(apiController, GetTicketQuery),
)

export const CompleteTicket = createAsyncThunk('CompleteTicket', async (data) =>
  AsyncQueryRequest(apiController + '/Complete', data, RequestMethods.PUT),
)

const FilterTickets = (data) => {
  const result = _.orderBy(data, ['state', (obj) => new Date(obj.created)], ['asc', 'desc'])
  return result
}

const TicketSlice = createSlice({
  name: 'Tickets',
  initialState,
  reducers: {
    Update: (state, action) => {
      console.log('opdate')
      state.Updated = state.Updated + 1
    },
    ObserveTicket: (state, value) => {
      const { payload } = value
      state.Observers = payload
    },
  },
  extraReducers: {
    [CompleteTicket.pending]: (state) => {
      state.isLoading = true
    },
    [CompleteTicket.fulfilled]: (state, action) => {
      const filtered = _.filter(state.Tickets, (x) => x.id !== action.payload.id)
      let targetTikket = action.payload

      targetTikket.messages = _.orderBy(
        targetTikket.messages,
        [(obj) => new Date(obj.created)],
        ['desc'],
      )

      const filteredTickets = FilterTickets([...filtered, targetTikket])
      state.TicketsActive = filteredTickets.filter(
        (x) => x.state === 'New' || x.state === 'NewReply',
      ).length
      state.Tickets = filteredTickets
    },
    [CompleteTicket.rejected]: (state, action) => {},
    [GetTikket.pending]: (state) => {
      state.isLoading = true
    },
    [GetTikket.fulfilled]: (state, action) => {
      let targetTikket = action.payload.value

      targetTikket = targetTikket.map((ticket) => {
        ticket.messages = _.orderBy(ticket.messages, [(obj) => new Date(obj.created)], ['desc'])

        return ticket
      })

      state.TicketsActive = targetTikket.filter(
        (x) => x.state === 'New' || x.state === 'NewReply',
      ).length
      state.Tickets = FilterTickets(targetTikket)
    },
    [GetTikket.rejected]: (state, action) => {},
  },
})

// export const { ToggleAside, ToggleDarkMode, Sidebar } = UserSlice.actions;

export default TicketSlice.reducer
