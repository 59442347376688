import { CButton } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const JsonSchemaView = () => {
  const { JWT } = useSelector((store) => store.Login)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (JWT == null) {
      window.close()
    }
    window.addEventListener('message', receiveMessage, false)
  }, [])

  const receiveMessage = (event) => {
    var origin = event.origin || event.originalEvent.origin // For Chrome, the origin property is in the event.originalEvent object.

    if (origin === window.location.protocol + '//' + window.location.host) {
      console.log('origin', origin)
      console.log(window.location.protocol + '//' + window.location.host)
      console.log('event', event.data)
      if (event.data.type === 'browserInterMessage') {
        setMessage(event.data.message)
      }
    }

    // ...
  }
  return (
    <div>
      <h1>Hello From JsonSchemaView</h1>
      <CButton onClick={() => setMessage('HI')}>add messaage</CButton>
      <p>{message}</p>
    </div>
  )
}

export default JsonSchemaView
