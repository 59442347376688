import { createSlice } from '@reduxjs/toolkit'

const initialState = () => {
  return {
    Toasts: {
      TopStart: [],
      Top: [],
      TopEnd: [],
      MiddleStart: [],
      Middle: [],
      MiddleEnd: [],
      BottomStart: [],
      Bottom: [],
      BottomEnd: [],
    },
    Modals: [],
  }
}

const Notifications = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {
    AddToast: (state, value) => {
      state.Toasts[value.payload.position].push(value.payload)
    },
    CloseToast: (state, value) => {
      state.Toasts[value.payload.position] = state.Toasts[value.payload.position].filter(
        (x) => x.id !== value.payload.id,
      )
    },
    AddModal: (state, value) => {
      state.Modals.push(value.payload)
    },
    CloseModal: (state, value) => {
      state.Modals = state.Modals.filter((x) => x.id !== value.payload)
    },
  },
})

export const { AddToast, CloseToast, CloseModal } = Notifications.actions

export default Notifications.reducer
