import { addMinutes, format, parseISO } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

export const DateOnly = 'dd-MM-yyyy'
export const HourMin = 'HH:mm'
export const DateHourMin = DateOnly + ' ' + HourMin
export const DateHourMinSec = DateOnly + ' HH:mm:ss'
export const DateMin = new Date(1, 1, 1)

export const cSharpMinDate = '0001-01-01T00:00:00'

export const DateOnlyPicker = 'yyyy/d/M'
export const Locale = 'da-DK'

export const DateReturn = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
export const OdataTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"

// Convert local date to UTC date
const timeZone = 'Europe/Copenhagen'
export const LocalToUtcDate = (localDate) =>
  format(addMinutes(localDate, localDate.getTimezoneOffset()), "yyyy-MM-dd'T'HH:mm:ss.SSS") //zonedTimeToUtc(localDate, timeZone) // 2023-02-01T13:00:00Z

// Convert UTC date to local date
export const UtcTolocalDate = (utcDate) => {
  return zonedTimeToUtc(utcDate, 'UTC') // 2023-02-01T14:00:00 in Europe/Copenhagen
}

// export const UtcTolocalDate = (utcDate) => {
//   console.log('utcDate', utcDate)
//   const parsedUtcDate = parseISO(utcDate)
//   console.log('parsedUtcDate', parsedUtcDate)
//   const utcDateTime = zonedTimeToUtc(parsedUtcDate, timeZone)
//   console.log('utcDateTime', utcDateTime)
//   return utcToZonedTime(utcDateTime, timeZone)
// }
