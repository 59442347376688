import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { BuildHeader } from 'src/Utils/headerBuilder'

import CreateRequest, { RequestMethods } from '../Helper/FetchHelpers'

const initialState = () => {
  return {
    Modules: [],
  }
}

export const GetModules = createAsyncThunk('GetModules', async (logLevel) => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(window.extended.MYAPP_API_ENDPOINT + '/Modules/get', requestOptions)
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const DeactivateModule = createAsyncThunk('DeactivateModule', async (id) => {
  var requestOptions = {
    method: RequestMethods.PUT,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Module/deactivate?id=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const ActivateModule = createAsyncThunk('ActivateModule', async (id) => {
  var requestOptions = {
    method: RequestMethods.PUT,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Module/activate?id=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

const ModulesSlice = createSlice({
  name: 'Logs',
  initialState,
  extraReducers: {
    [GetModules.pending]: (state) => {
      state.isLoading = true
    },
    [GetModules.fulfilled]: (state, action) => {
      state.Modules = action.payload.object
    },
    [GetModules.rejected]: (state, action) => {},

    [ActivateModule.pending]: (state) => {
      state.isLoading = true
    },
    [ActivateModule.fulfilled]: (state, action) => {
      state.Modules = [
        ..._.filter(state.Modules, (x) => x.id !== action.payload.id),
        action.payload,
      ]
    },
    [ActivateModule.rejected]: (state, action) => {},

    [DeactivateModule.pending]: (state) => {
      state.isLoading = true
    },
    [DeactivateModule.fulfilled]: (state, action) => {
      state.Modules = [
        ..._.filter(state.Modules, (x) => x.id !== action.payload.id),
        action.payload,
      ]
    },
    [DeactivateModule.rejected]: (state, action) => {},
  },
})

// export const { ToggleAside, ToggleDarkMode, Sidebar } = UserSlice.actions;

export default ModulesSlice.reducer
