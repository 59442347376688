import { createSlice } from '@reduxjs/toolkit'
import { EmailReducers, EmailTemplateInitialState } from './EmailTemplates'
import { EventServiceInitialState, EventServiceReducers } from './EventServiceState'

const initialState = () => {
  return {
    ...EmailTemplateInitialState,
    ...EventServiceInitialState,
  }
}

const TemplatesSlice = createSlice({
  name: 'Administration',
  initialState,
  reducers: {},
  extraReducers: {
    ...EmailReducers,
    ...EventServiceReducers,
  },
})

// export const { Logout, SetSessionTimeoutSecounds, Connected, Disconnected, ConnectionLost } =
//   TemplatesSlice.actions

export default TemplatesSlice.reducer
