import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncFormRequest } from 'src/Utils/headerBuilder'
import { RequestMethods } from '../Helper/FetchHelpers'
import { AddPropColor } from 'src/Utils/SmartTableProps'
import _ from 'lodash'

const apiController = '/Event/'

export const EventServiceInitialState = { ServiceEvents: [] }

const colorUnusedTemplateEmailId = {
  templateEmailId: (value) => value === null && { color: 'warning', align: 'middle' },
}

//* Actions */
export const GetAllEventServices = createAsyncThunk('GetAllEventServices', async (data) =>
  AsyncFormRequest(apiController + 'GetAll', data),
)

export const CreateEvent = createAsyncThunk('CreateEvent', async (data) =>
  AsyncFormRequest(apiController + 'Create', data, RequestMethods.POST),
)

export const UpdateEvent = createAsyncThunk('UpdateEvent', async (data) =>
  AsyncFormRequest(apiController + 'Update', data, RequestMethods.PUT),
)

//* Reducers */

export const EventServiceReducers = {
  [GetAllEventServices.pending]: (state) => {
    state.isLoading = true
  },
  [GetAllEventServices.fulfilled]: (state, action) => {
    state.ServiceEvents = _.orderBy(
      AddPropColor(colorUnusedTemplateEmailId, action.payload),
      ['id'],
      ['desc'],
    )
  },
  [GetAllEventServices.rejected]: (state, action) => {},

  [CreateEvent.pending]: (state) => {
    state.isLoading = true
  },
  [CreateEvent.fulfilled]: (state, action) => {
    state.ServiceEvents = _.orderBy([...state.ServiceEvents, action.payload], ['id'], ['desc'])
  },
  [CreateEvent.rejected]: (state, action) => {},

  [UpdateEvent.pending]: (state) => {
    state.isLoading = true
  },
  [UpdateEvent.fulfilled]: (state, action) => {
    console.log('UpdateEvent.fulfilled')
    let serviceEvents = _.filter(state.ServiceEvents, (x) => x.id !== action.payload.id)

    state.ServiceEvents = _.orderBy(
      AddPropColor(colorUnusedTemplateEmailId, [...serviceEvents, action.payload]),
      ['id'],
      ['desc'],
    )
  },
  [UpdateEvent.rejected]: (state, action) => {},
}
