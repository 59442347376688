import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import history from 'src/Utils/history'
import { decodeToken } from 'react-jwt'
import { isBefore } from 'date-fns'
import connection from 'src/Utils/SignalR/SignalR'
import { HubConnectionState } from '@microsoft/signalr'
import { RequestMethods } from '../Helper/FetchHelpers'
import { AsyncFormRequest, AsyncQueryRequest } from 'src/Utils/headerBuilder'
const apiController = '/Authenticate/'

const initialState = () => {
  if (localStorage.Login !== undefined) {
    var _localStorage = JSON.parse(localStorage.Login)

    if (isBefore(new Date(_localStorage.JWT.expiration), new Date())) {
      console.log('session timeout')
    }
    return JSON.parse(localStorage.Login)
  }

  return {
    JWT: null,
    claims: null,
    Connected: false,
    ConnectionClosed: false,
    errorMessage: null,
  }
}

export const UpdateProduct = createAsyncThunk('UpdateProduct', async (data) =>
  AsyncFormRequest(apiController + 'update', data, RequestMethods.PUT),
)

export const DeleteProduct = createAsyncThunk('DeleteProduct', async (query) =>
  AsyncQueryRequest(apiController + 'delete', query, RequestMethods.DELETE),
)

export const PostLogin = createAsyncThunk('Login', async (data) =>
  AsyncFormRequest(apiController + 'login', data, RequestMethods.POST),
)

export const RenewLogin = createAsyncThunk('RenewLogin', async (query) =>
  AsyncQueryRequest(apiController + 'refreshToken', query, RequestMethods.POST),
)

export const GetUserData = createAsyncThunk('GetUserData', async (data) =>
  AsyncFormRequest('/User/get'),
)

const LoginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    Logout: (state, data) => {
      console.log('Logout')
      state.isLoading = false
      state.user = null
      if (connection.state === HubConnectionState.Connected) {
        connection.stop()
      }

      localStorage.removeItem('Login')
      localStorage.removeItem('CurrentUser')
      history.go('/login')
    },
    Connected: (state) => {
      state.Connected = true
    },
    Disconnected: (state) => {
      state.Connected = false
    },
    ConnectionLost: (state) => {
      state.ConnectionClosed = true
    },
  },
  extraReducers: {
    'LockUser/fulfilled': (state, data) => {
      if (data.payload.id === state.claims.UserId) {
        state.isLoading = false
        state.user = null
        if (connection.state === HubConnectionState.Connected) {
          connection.stop()
        }

        localStorage.removeItem('Login')
        localStorage.removeItem('CurrentUser')
        history.go('/login?msg=du blev logge ud af admin')
      }
    },
    [PostLogin.pending]: (state) => {
      state.isLoading = true
    },
    [PostLogin.fulfilled]: (state, action) => {
      state.isLoading = false
      state.JWT = {
        token: action.payload.token,
        expiration: action.payload.expiration,
        refreshToken: action.payload.refreshToken,
      }

      state.claims = decodeToken(action.payload.token)

      if (typeof state.claims === 'string') {
        state.roles = [state.claims.role]
      } else {
        state.roles = state.claims.role
      }
      localStorage.Login = JSON.stringify(state)
    },
    [PostLogin.rejected]: (state, action) => {
      console.log('Rejected', action.error.message)
      // console.log(action.json());
      state.errorMessage = action.error.message
      state.isLoading = false
    },

    [GetUserData.pending]: (state) => {},
    [GetUserData.fulfilled]: (state, action) => {
      console.log('GetUserData.fulfilled', action)
      state.user = action.payload
      localStorage.Login = JSON.stringify(state)
    },
    [GetUserData.rejected]: (state) => {},

    [RenewLogin.pending]: (state) => {
      state.isLoading = true
    },
    [RenewLogin.fulfilled]: (state, action) => {
      console.log('RenewLogin.fulfilled')
      state.JWT = {
        token: action.payload.token,
        expiration: action.payload.expiration,
        refreshToken: action.payload.refreshToken,
      }

      state.claims = decodeToken(action.payload.token)
      if (typeof state.claims === 'string') {
        state.roles = [state.claims.role]
      } else {
        state.roles = state.claims.role
      }

      localStorage.Login = JSON.stringify(state)
    },
    [RenewLogin.rejected]: (state, action) => {
      console.log('RenewLogin.Rejected', action)
      state.isLoading = false
      // console.log(action.json());
      console.log(action.payload)
      state.errorMessage = action.error.message
    },
  },
})

// console.log(LoginSlice);

export const { Logout, SetSessionTimeoutSecounds, Connected, Disconnected, ConnectionLost } =
  LoginSlice.actions

export default LoginSlice.reducer
