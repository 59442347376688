import _ from 'lodash'

const isAnyKeyUndefined = (obj) => {
  for (let key in obj) {
    if (obj[key] === undefined) {
      return { state: true, key }
    }
  }
  for (let key in obj) {
    return { state: false, key }
  }
}

export const BuildOdateFilter = (paginated, input) => {
  const getKeyState = isAnyKeyUndefined(input)
  let inputVariables = { ...paginated.inputVariables, ...input }
  if (getKeyState.state) {
    //State is Undefined and should not be Propegate to the Result
    delete inputVariables[getKeyState.key]
  }

  let parsedProps = {}
  for (const [key, value] of Object.entries(inputVariables)) {
    if (_.isArray(value)) {
      if (!_.isEmpty(value)) {
        let orMapper = []
        for (let index = 0; index < value.length; index++) {
          orMapper.push({ [key]: value[index] })
        }
        parsedProps = { and: { ...parsedProps, or: orMapper } }
      }
    } else {
      if (value === undefined || value === null || value === '') continue
      if (value !== undefined || value !== null || value !== '') {
        parsedProps = { ...parsedProps, and: { ...parsedProps.and, [key]: value } }
      }
    }
  }

  return { inputVariables, parsedProps }
}
