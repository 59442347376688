import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncFormRequest } from 'src/Utils/headerBuilder'

const apiController = '/Admin/'

export const EmailTemplateInitialState = { EmailTemplates: [] }

export const GetITLEmailTemplates = createAsyncThunk('GetITLEmailTemplates', async (data) => {
  return AsyncFormRequest(apiController + 'GetEmails', data)
})

//* Reducers */

export const EmailReducers = {
  [GetITLEmailTemplates.pending]: (state) => {
    state.isLoading = true
  },
  [GetITLEmailTemplates.fulfilled]: (state, action) => {
    console.log('GetEmailWrapper.fulfilled')
    state.EmailTemplates = action.payload
  },
  [GetITLEmailTemplates.rejected]: (state, action) => {},
}
