import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import store from 'src/store'
import { BuildHeader } from 'src/Utils/headerBuilder'

import CreateRequest, { RequestMethods } from '../Helper/FetchHelpers'

const initialState = () => {
  let initialState = {
    currentUserId: null,
    userList: null,
    Roles: null,
    connectedUsers: [],
    MessageList: [],
    UsersOnURl: [],
    AccessLog: {},
  }
  if (localStorage.CurrentUser !== undefined) {
    return JSON.parse(localStorage.CurrentUser)
  }

  return initialState
}
export const GetUsers = CreateRequest('GetUsers', '/Users/get')

export const GetLoggedInUser = CreateRequest('GetUser', '/User/get')

export const UpdateUser = createAsyncThunk('UpdateUser', async (user, password) => {
  var raw = JSON.stringify(user)
  var requestOptions = {
    method: 'PUT',
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(window.extended.MYAPP_API_ENDPOINT + '/User/update', requestOptions)
  const formattedResponse = await response.json()
  return formattedResponse
})

export const UpdateUserPassword = createAsyncThunk('UpdateUserPassword', async (user, password) => {
  var raw = JSON.stringify(user)
  var requestOptions = {
    method: 'PUT',
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/ChangePassword',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const RegistereUser = createAsyncThunk('RegistereUser', async (user, password) => {
  var raw = JSON.stringify(user)
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/user/register',
    requestOptions,
  )

  console.log(response)

  if (!response.ok) {
    console.log('test', res)
    const res = await response.json()
    // throw new Error(res)

    const ToastData = {
      Title: 'error',
      Message: res,
      Color: 'danger',
    }

    store.dispatch({ type: 'Notifications/AddToast', payload: ToastData })
  }

  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const GetRoles = CreateRequest('GetRoles', `/Roles/get`)

export const AddRole = createAsyncThunk('AddUserRole', async (role) => {
  var raw = JSON.stringify(role)
  var requestOptions = {
    method: 'PUT',
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/admin/role/add',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const RemoveRole = createAsyncThunk('RemoveUserRole', async (role) => {
  var raw = JSON.stringify(role)
  var requestOptions = {
    method: 'DELETE',
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/admin/role/remove',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const LockUser = createAsyncThunk('LockUser', async (id) => {
  var requestOptions = {
    method: 'PUT',
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/admin/lock?id=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const UserDelete = createAsyncThunk('UserDelete', async (id) => {
  var requestOptions = {
    method: RequestMethods.DELETE,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/admin/delete?Id=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const UnlockUser = createAsyncThunk('UnlockUser', async (id) => {
  var raw = JSON.stringify({ id })
  var requestOptions = {
    method: 'PUT',
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/admin/unlock?id=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const GetAccessLog = createAsyncThunk('GetAccessLog', async (id) => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/getAccessLog?userId=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return { object: formatttedResponse.object, requestUserId: id }
})

const UserSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    UsersOnURl: (state, value) => {
      state.UsersOnURl = value.payload
    },
    ConnectedUsers: (state, value) => {
      state.connectedUsers = _.orderBy(value.payload, ['fullname'], ['asc'])
    },
    Message: (state, value) => {
      state.MessageList.push(value.payload)
    },
    roles: (state, data) => {
      let currentUser = state.userList.find((x) => x.id === state.currentUserId)
      currentUser.roles = data.payload
      state.userList = _.orderBy(
        [...state.userList.filter((x) => x.id !== state.currentUserId), currentUser],
        ['title'],
        ['asc'],
      )
    },
  },
  extraReducers: {
    'Login/fulfilled': (state, action) => {
      state.currentUserId = action.payload.user.id
      state.userList = [action.payload.user]
      localStorage.CurrentUser = JSON.stringify(state)
    },
    [GetLoggedInUser.pending]: (state) => {
      state.isLoading = true
    },
    [GetLoggedInUser.fulfilled]: (state, action) => {
      state.currentUserId = action.payload.id
      state.userList = [action.payload]
      localStorage.CurrentUser = JSON.stringify(state)
    },
    [GetLoggedInUser.rejected]: (state, action) => {},

    [GetUsers.pending]: (state) => {
      state.isLoading = true
    },
    [GetUsers.fulfilled]: (state, action) => {
      if (!_.isEqual(state.userList, action)) {
        state.userList = _.orderBy(action.payload, ['title'], ['asc'])
      }
    },
    [GetUsers.rejected]: (state, action) => {},

    [UpdateUser.pending]: (state) => {
      state.isLoading = true
    },
    [UpdateUser.fulfilled]: (state, action) => {
      let currentList = state.userList.filter((x) => x.id !== action.payload.id)
      currentList = [...currentList, action.payload]
      state.userList = _.orderBy(currentList, ['title'], ['asc'])

      if (state.currentUserId === action.payload.id) {
        let currentUserObj = JSON.parse(localStorage.CurrentUser)
        currentUserObj.userList = _.orderBy(
          [...currentList.filter((x) => x.id !== action.payload.id), action.payload],
          ['title'],
          ['asc'],
        )
        localStorage.CurrentUser = JSON.stringify(currentUserObj)
      }
    },
    [UpdateUser.rejected]: (state, action) => {},

    [UserDelete.pending]: (state) => {
      state.isLoading = true
    },
    [UserDelete.fulfilled]: (state, action) => {
      console.log(action.payload)
      let currentList = state.userList.filter((x) => x.id !== action.payload.id)
      currentList = [
        ...currentList,
        { ...state.userList.find((x) => x.id === action.payload.id), isDeleted: true },
      ]
      state.userList = _.orderBy(currentList, ['title'], ['asc'])
    },
    [UserDelete.rejected]: (state, action) => {},

    [RegistereUser.pending]: (state) => {
      state.isLoading = true
    },
    [RegistereUser.fulfilled]: (state, action) => {
      let currentList = state.userList.filter((x) => x.id !== action.payload.id)
      currentList = [...currentList, action.payload]
      state.userList = _.orderBy(currentList, ['title'], ['asc'])
    },
    [RegistereUser.rejected]: (state, action) => {},

    [GetRoles.pending]: (state) => {
      state.isLoading = true
    },
    [GetRoles.fulfilled]: (state, action) => {
      const SelectRoles = action.payload.result.map((role) => {
        return { ...role, label: role.name, value: role.name }
      })
      state.Roles = SelectRoles
    },
    [GetRoles.rejected]: (state, action) => {},

    [AddRole.pending]: (state, action) => {},
    [AddRole.fulfilled]: (state, action) => {
      const userToList = action.payload.object

      state.userList = _.orderBy(
        [...state.userList.filter((x) => x.id !== userToList.id), userToList],
        ['title'],
        ['asc'],
      )
    },
    [AddRole.rejected]: (state, action) => {},

    [RemoveRole.pending]: (state, action) => {},
    [RemoveRole.fulfilled]: (state, action) => {
      const userToList = action.payload.object

      state.userList = _.orderBy(
        [...state.userList.filter((x) => x.id !== userToList.id), userToList],
        ['title'],
        ['asc'],
      )
    },
    [RemoveRole.rejected]: (state, action) => {},

    [LockUser.pending]: (state, action) => {},
    [LockUser.fulfilled]: (state, action) => {
      const userToList = action.payload
      state.userList = _.orderBy(
        [...state.userList.filter((x) => x.id !== userToList.id), userToList],
        ['title'],
        ['asc'],
      )
    },
    [LockUser.rejected]: (state, action) => {},

    [UnlockUser.pending]: (state, action) => {},
    [UnlockUser.fulfilled]: (state, action) => {
      const userToList = action.payload
      state.userList = _.orderBy(
        [...state.userList.filter((x) => x.id !== userToList.id), userToList],
        ['title'],
        ['asc'],
      )
    },
    [UnlockUser.rejected]: (state, action) => {},

    [GetAccessLog.pending]: (state, action) => {},
    [GetAccessLog.fulfilled]: (state, action) => {
      console.log(action.payload)
      state.AccessLog = {
        ...state.AccessLog,
        [action.payload.requestUserId]: action.payload.object,
      }
    },
    [GetAccessLog.rejected]: (state, action) => {},
  },
})

export const { SetUser } = UserSlice.actions

export default UserSlice.reducer
