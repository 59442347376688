import { createAsyncThunk } from '@reduxjs/toolkit'
import { BuildHeader } from 'src/Utils/headerBuilder'

export const RequestMethods = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  // CONNECT: "CONNECT",
  // HEAD: "HEAD",
  // OPTIONS:"OPTIONS",
  // PATCH:"PATCH",
  // TRACE: "TRACE",
})

export const RequestMethodFromEnum = (value) => {
  switch (value) {
    case 0:
      return 'GET'
    case 1:
      return 'POST'
    case 2:
      return 'PUT'
    case 3:
      return 'DELETE'
    default:
      alert('unknown Request method')
      throw new Error('Request method Unknown')
  }
}

const CreateRequest = (actionName, URL, MethodType = RequestMethods.GET, data = null) => {
  return createAsyncThunk(actionName, async () => {
    var requestOptions = {
      method: MethodType,
      headers: BuildHeader(),
      // redirect: "follow",
    }

    if (data !== null) {
      if (typeof data === 'object') {
        requestOptions = { ...requestOptions, body: JSON.stringify(data) }
      } else {
        requestOptions = { ...requestOptions, body: data }
      }
    }
    const response = await fetch(window.extended.MYAPP_API_ENDPOINT + URL, requestOptions)
    const formatttedResponse = await response.json()
    return formatttedResponse
  })
}

export default CreateRequest
