import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import DevHeader from './views/_Components/devHelpers/DevHeader'

import './Utils/consoleOverload'

import 'font-awesome/css/font-awesome.min.css'

window.extended.Version = '2023.11.21'

const root = createRoot(document.getElementById('root'))
root.render(
  <React.Fragment>
    <Provider store={store}>
      {process.env.NODE_ENV === 'development' && <DevHeader></DevHeader>}
      <App />
    </Provider>
  </React.Fragment>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
