import _ from 'lodash'

export const AddPropColor = (prop, data) => {
  let newData = []
  data.forEach((elm) => {
    let element = elm
    for (const [key, value] of Object.entries(elm)) {
      if (_.includes(_.keys(prop), key)) {
        element = { ...element, _props: prop[key](value) }
      }
    }
    newData.push(element)
  })

  return newData
}
