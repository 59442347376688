import React from 'react'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { BuildHeader } from 'src/Utils/headerBuilder'
import { RequestMethods } from '../Helper/FetchHelpers'

const initialState = () => {
  return {
    Templates: [],
    EmptyTemplate: [],
  }
}

export const GetTemplateDesignes = createAsyncThunk('GetTemplateDesignes', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/getall',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const UpdateTemplateDesign = createAsyncThunk('UpdateTemplateDesign', async (template) => {
  delete template.HtmlContent

  var raw = JSON.stringify(template)
  var requestOptions = {
    method: RequestMethods.PUT,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/update',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const InsertTemplateDesign = createAsyncThunk('InsertTemplateDesign', async (template) => {
  delete template.binaryTemplate

  var raw = JSON.stringify(template)
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/insert',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const CreateTemplate = createAsyncThunk('CreateTemplate', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/generateHtmlTemplate',
    requestOptions,
  )

  const formatttedResponse = await response.json()
  return formatttedResponse
})

const TemplateDesignerState = createSlice({
  name: 'TemplateDesigner',
  initialState,
  reducers: {},
  extraReducers: {
    [GetTemplateDesignes.pending]: (state) => {
      state.isLoading = true
    },
    [GetTemplateDesignes.fulfilled]: (state, action) => {
      state.Templates = _.orderBy(action.payload, ['id'], ['asc'])
    },
    [GetTemplateDesignes.rejected]: (state, action) => {},

    [UpdateTemplateDesign.pending]: (state) => {
      state.isLoading = true
    },
    [UpdateTemplateDesign.fulfilled]: (state, action) => {
      var target = state.Templates.find((x) => x.id === action.payload.id)
      target = action.payload.object

      state.Templates = _.orderBy(
        [...state.Templates.filter((x) => x.id !== action.payload.id), target],
        ['id'],
        ['asc'],
      )
    },

    [InsertTemplateDesign.pending]: (state) => {
      state.isLoading = true
    },
    [InsertTemplateDesign.fulfilled]: (state, action) => {
      state.Templates = _.orderBy([...state.Templates, action.payload], ['id'], ['asc'])
    },

    [InsertTemplateDesign.rejected]: (state, action) => {},

    [CreateTemplate.pending]: (state) => {
      state.isLoading = true
    },
    [CreateTemplate.fulfilled]: (state, action) => {
      state.EmptyTemplate = action.payload
    },
    [CreateTemplate.rejected]: (state, action) => {},
  },
})

// export const { UpdateStates } = TemplateDesignerState.actions

export default TemplateDesignerState.reducer
