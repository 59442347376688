import store from 'src/store'

export const CustomerEnumKey = 'CustomerType'
export const ContractStatusTypeEnumKey = 'ContractStatusType'
export const ContractActionTypeEnumKey = 'ContractActionType'
export const GenderTypeEnumKey = 'GenderType'
export const CustomerTypeEnum = 'CustomerType'
export const VisiblilityTypeEnum = 'VisiblilityType'
export const CreationPointTypeEnum = 'CreationPointType'
export const SchemaVisibilityTypeEnum = 'SchemaVisibilityType'
export const RequestMethodTypeEnum = 'RequestMethodType'
export const TokenLimitationTypeEnum = 'TokenLimitationType'
export const LogLevelEnum = 'LogLevel'
export const TicketEnum = 'TicketEnum'
export const EditorAction = 'EditorAction'

export const AccountingSystemTypeEnum = 'AccountingSystemType'
export const ProductCatalogTypeEnum = 'ProductCatalogType'
export const CertificateType = 'CertificateType'
export const ScheduleType = 'ScheduleType'

const ParseEnum = (enumType, value) => {
  if (value === undefined) return 'EnumError'
  var enums = store.getState().Layout.Enums
  if (enums === null) return null
  var targetEnum = enums[enumType]
  return targetEnum[value]
}

export default ParseEnum

export const ParseOdataEnums = (enumType, value) => {
  if (value === undefined) return 'EnumError'
  var enums = store.getState().Layout.OdataEnums
  if (enums === null) return null
  var targetEnum = enums[enumType]
  return targetEnum[value]
}

export const GetEnumsList = (enumType) => {
  var enums = store.getState().Layout.Enums
  if (enums === null) return null
  var targetEnum = enums[enumType]
  let List = []
  Object.keys(targetEnum).map((key) => {
    List.push({ label: targetEnum[key], value: key })
    return null
  })

  return List
}

export const GetOdataEnumsList = (enumType) => {
  var enums = store.getState().Layout.OdataEnums
  if (enums === null) return null
  var targetEnum = enums[enumType]
  let List = []
  Object.keys(targetEnum).map((key) => {
    List.push({ label: targetEnum[key], value: key })
    return null
  })

  return List
}
