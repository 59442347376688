import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { AsyncFormRequest } from 'src/Utils/headerBuilder'
import { RequestMethods } from '../Helper/FetchHelpers'

const apiController = '/Layouts/'

const initialState = () => {
  return {
    sidebarShow: true,
    asideShow: false,
    sidebarUnfoldable: false,
    theme: 'default',
    activeKey: 1,
    Enums: null,
    OdataEnums: null,
    ShowInprogressModal: false,

    JsonSchemas: [],
  }
}

export const GetEnums = createAsyncThunk('GetEnums', async (data) => AsyncFormRequest('/Enum/get'))
export const GetOdataEnums = createAsyncThunk('GetOdataEnums', async (data) =>
  AsyncFormRequest('/Enum/getOdata'),
)

export const GetJsonSchemas = createAsyncThunk('GetJsonSchemas', async (data) =>
  AsyncFormRequest(apiController + 'get'),
)

export const CreateJsonSchemas = createAsyncThunk('CreateJsonSchemas', async (data) =>
  AsyncFormRequest(apiController + 'create', data, RequestMethods.POST),
)

export const UpdateJsonSchema = createAsyncThunk('UpdateJsonSchema', async (data) =>
  AsyncFormRequest(apiController + 'update', data, RequestMethods.PUT),
)

const LayoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    ToggleAside: (state, value) => {
      state.asideShow = !state.asideShow
    },
    Sidebar: (state, val) => {
      state.sidebarShow = val.payload
    },
    SetTheme: (state, value) => {
      state.theme = value.payload
    },
    SetActiveKey: (state, value) => {
      state.activeKey = value.payload
    },
    SetSidebarUnfoldable: (state, value) => {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    InprogressModal: (state, val) => {
      state.ShowInprogressModal = val.payload
    },
  },
  extraReducers: {
    [GetEnums.pending]: (state) => {
      state.isLoading = true
    },
    [GetEnums.fulfilled]: (state, action) => {
      state.Enums = action.payload
    },
    [GetEnums.rejected]: (state, action) => {},
    [GetOdataEnums.pending]: (state) => {
      state.isLoading = true
    },
    [GetOdataEnums.fulfilled]: (state, action) => {
      state.OdataEnums = action.payload
    },
    [GetOdataEnums.rejected]: (state, action) => {},

    [GetJsonSchemas.pending]: (state) => {
      state.isLoading = true
    },
    [GetJsonSchemas.fulfilled]: (state, action) => {
      state.JsonSchemas = _.orderBy(action.payload, ['id'], ['asc'])
    },
    [GetJsonSchemas.rejected]: (state, action) => {},

    [CreateJsonSchemas.pending]: (state) => {
      state.isLoading = true
    },
    [CreateJsonSchemas.fulfilled]: (state, action) => {
      state.JsonSchemas = [...state.JsonSchemas, action.payload]
    },
    [CreateJsonSchemas.rejected]: (state, action) => {},

    [UpdateJsonSchema.pending]: (state) => {
      state.isLoading = true
    },
    [UpdateJsonSchema.fulfilled]: (state, action) => {
      state.JsonSchemas = _.orderBy(
        [
          ..._.filter(state.JsonSchemas, (x) => x.id !== JSON.parse(action.payload.id)),
          action.payload,
        ],
        ['id'],
        ['asc'],
      )
    },
    [UpdateJsonSchema.rejected]: (state, action) => {},
  },
})

export const {
  ToggleAside,
  SetTheme,
  Sidebar,
  SetActiveKey,
  SetSidebarUnfoldable,
  InprogressModal,
} = LayoutSlice.actions

export default LayoutSlice.reducer
