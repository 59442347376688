import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { current } from 'immer'
import {
  AsyncFormRequest,
  AsyncQueryRequest,
  AsyncOdataObjectQuery,
  BuildHeader,
} from 'src/Utils/headerBuilder'
import { RequestMethods } from '../Helper/FetchHelpers'
import { completedFilter, manualFilter } from 'src/Utils/QueryFilters'
import { BuildOdateFilter } from 'src/Utils/Odata/Filterbuilder'

const initialState = () => {
  return {
    Observers: [],

    bookmarks: [],
    BookmarkedContracts: [],
    Stats: [],

    UserFinishedContractsCount: [],

    paginatedFailed: {
      filter: manualFilter,
      odataQuery: {
        filter: manualFilter,
        expand: ['DeliveryAddress'],
        orderBy: ['id desc'],
        count: true,
        top: 10,
        skip: 0,
      },
      inputVariables: {},
      currentPage: 1,
      isLoading: true,
      pageCount: 0,
      items: [],
    },
    paginatedCompleted: {
      filter: completedFilter,
      odataQuery: {
        filter: completedFilter,
        expand: ['DeliveryAddress'],
        orderBy: ['id desc'],
        count: true,
        top: 10,
        skip: 0,
      },
      inputVariables: {},
      currentPage: 1,
      isLoading: true,
      pageCount: 0,
      items: [],
    },

    paginationCurrentUser: {
      // filter: Are Set in DefaultLayout,
      odataQuery: {
        // filter: Are Set in DefaultLayout,
        orderBy: ['changed desc'],
        count: true,
        top: 10,
        skip: 0,
      },
      // inputVariables: Are Set in DefaultLayout,
      currentPage: 1,
      isLoading: true,
      pageCount: 0,
      items: [],
    },

    IncomingContracts: [],
  }
}

const apiController = '/Contract/'

export const GetCompletedContractsOdata = createAsyncThunk(
  'GetCompletedContractsOdata',
  async (filter) => AsyncOdataObjectQuery('/Contract', filter),
)

export const GetFailedContractsOdata = createAsyncThunk('GetFailedContractsOdata', async (filter) =>
  AsyncOdataObjectQuery('/Contract', filter),
)

export const GetLatestUserContractsOdata = createAsyncThunk(
  'GetLatestUserContractsOdata',
  async (filter) => {
    const _filter = {
      ...filter,
      filter: {
        ...filter.filter,
        or: [
          { CompletedEditor: JSON.parse(localStorage.CurrentUser).currentUserId },
          { RejectedEditor: JSON.parse(localStorage.CurrentUser).currentUserId },
        ],
      },
    }
    return await AsyncOdataObjectQuery('/Contract', _filter)
  },
)

const ContractStatsQuery = {
  transform: {
    groupBy: {
      properties: ['ContractStatusType'],
      transform: {
        aggregate: {
          $count: {
            as: 'count',
          },
        },
      },
    },
  },
}

export const GetContractStatsOdata = createAsyncThunk('GetContractStatsOdata', async () =>
  AsyncOdataObjectQuery('/Contract', ContractStatsQuery),
)

const UserFinishedCount = {
  transform: {
    groupBy: {
      properties: ['CompletedEditor', 'RejectedEditor'],
      transform: {
        aggregate: {
          $count: {
            as: 'count',
          },
        },
      },
    },
  },
}
export const GetUserContractStatsOdata = createAsyncThunk('GetUserContractStatsOdata', async () =>
  AsyncOdataObjectQuery('/Contract', UserFinishedCount),
)

export const RejectContract = createAsyncThunk('RejectContract', async (query) =>
  AsyncQueryRequest(apiController + 'reject', query, RequestMethods.DELETE),
)
export const CompleteContract = createAsyncThunk('CompleteContract', async (query) =>
  AsyncQueryRequest(apiController + 'complete', query, RequestMethods.PUT),
)

export const CreateContract = createAsyncThunk('CreateContract', async (data) =>
  AsyncFormRequest(apiController + 'create', data),
)

export const GetBookmarks = createAsyncThunk('GetBookmarks', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/bookmarks/getActive',
    requestOptions,
  )

  if (!response.ok) {
    const res = await response.json()
    throw new Error(res)
  }

  const formatttedResponse = await response.json()

  return await FetchBookmarkedContracts(formatttedResponse)
})

export const BookmarkToggle = createAsyncThunk('BookmarkToggle', async (contractId) => {
  var requestOptions = {
    method: RequestMethods.PUT,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/User/bookmark/toggle?contractId=' + contractId,
    requestOptions,
  )

  if (!response.ok) {
    const res = await response.json()
    throw new Error(res)
  }

  const formatttedResponse = await response.json()

  return await FetchBookmarkedContracts(formatttedResponse)
})

export const AddMessage = createAsyncThunk('AddMessage', async (data) =>
  AsyncFormRequest(apiController + 'AddMessage', data, RequestMethods.POST),
)

const FetchBookmarkedContracts = async (data) => {
  if (_.isEmpty(data)) {
    return {
      bookmarks: [],
      BookmarkedContracts: [],
    }
  }
  let filter = {
    or: _.map(data, (s) => {
      return { Id: s.contractId }
    }),
  }

  var BookmarkedContracts = await AsyncOdataObjectQuery('/Contract', { filter })

  return {
    bookmarks: filter.or.map((x) => x.Id),
    BookmarkedContracts: BookmarkedContracts.value,
  }
}

const UserSlice = createSlice({
  name: 'Contracts',
  initialState,
  reducers: {
    update: (state, value) => {
      const { payload } = value
      state = _.merge(state, payload)
    },
    ObserveContract: (state, value) => {
      const { payload } = value
      state.Observers = payload
    },
    ConnectedUsers: (state, value) => {
      state.connectedUsers = value.payload
    },
    Created: (state, value) => {
      var statusType = value.payload.contractStatusTypeId
      var currentContractId = value.payload.id

      //Inprogress
      if (statusType === 15) {
        state.Inprogress = [
          ...state.Inprogress.filter((x) => x.id !== currentContractId),
          value.payload,
        ]
      }

      ///Failed
      if (statusType >= 0 && statusType <= 20 && statusType !== 15) {
        state.Failed = [...state.Failed.filter((x) => x.id !== currentContractId), value.payload]
      }

      //completed
      if (statusType > 20) {
        state.Completed = [
          ...state.Completed.filter((x) => x.id !== currentContractId),
          value.payload,
        ]
      }
      // state.summariesCount = state.summariesCount + 1
    },

    IncomingContracts: (state, value) => {
      state.IncomingContracts = value.payload

      const incommingIsCompleted = value.payload.contractStatusTypeId > 20
      const inCommingIsProgress = value.payload.contractStatusTypeId === 15
      const incommingIsFailed =
        value.payload.contractStatusTypeId >= 0 &&
        value.payload.contractStatusTypeId <= 20 &&
        value.payload.contractStatusTypeId !== 15

      if (incommingIsCompleted) {
        // Check if the incoming contract is contained in paginatedFailed.items
        const index = state.paginatedFailed.items.findIndex((x) => x.id === value.payload.id)
        if (index !== -1) {
          // Remove the incoming contract from paginatedFailed.items
          state.paginatedFailed.items.splice(index, 1)
        }
      }

      if (incommingIsFailed) {
        const filter = state.paginatedFailed.odataQuery.orderBy[0].split(' ')
        const selector = filter[0]
        const order = filter[1]

        // Check if the incoming contract is contained in paginatedCompleted.items
        // const index = state.paginatedFailed.items.findIndex((x) => x.id === value.payload.id)
        // if (index !== -1) {
        //   // Remove the incoming contract from paginatedCompleted.items
        //   state.paginatedFailed.items = [
        //     _.orderBy(
        //       ...state.paginatedFailed.items.filter((x) => x.id !== value.payload.id),
        //       value.payload,
        //       [selector],
        //       [order],
        //     ),
        //   ]
        // }
      }

      //Filter CompletedContracts
    },
    UpdateFilter: (state, value) => {
      for (const [key, item] of Object.entries(value.payload)) {
        //state[key] = _.merge(state[key], value.payload[key])

        state[key] = {
          ...state[key],
          ...value.payload[key],
        }
      }
    },

    UpdateInput: (state, value) => {
      const { payload } = value
      let { key, input } = payload

      const paginated = current(state[key])
      const { inputVariables, parsedProps } = BuildOdateFilter(paginated, input)

      state[key] = {
        ...state[key],
        inputVariables,
        currentPage: 1,
        odataQuery: {
          ...state[key].odataQuery,
          skip: state[key].odataQuery.top * (1 - 1),
          filter: { ...parsedProps, ...paginated.filter },
        },
      }
      // state[payload.key] = payload.value
    },

    UpdateActivePage: (state, value) => {
      const { payload } = value
      const { key, activePage } = payload

      state[key] = {
        ...state[key],
        currentPage: activePage,
        odataQuery: {
          ...state[key].odataQuery,
          skip: state[key].odataQuery.top * (activePage - 1),
        },
      }
    },

    UpdatePageItemsCount: (state, value) => {
      const { payload } = value
      const { key, pageItemsCount } = payload

      state[key] = {
        ...state[key],
        odataQuery: {
          ...state[key].odataQuery,
          top: pageItemsCount,
          skip: pageItemsCount * (state[key].currentPage - 1),
        },
      }
    },
    UpdateColumnSort: (state, value) => {
      const { payload } = value
      const { key, SortOrder } = payload

      state[key] = {
        ...state[key],
        odataQuery: {
          ...state[key].odataQuery,
          orderBy: [SortOrder],
        },
      }
    },

    ClearInput: (state, value) => {
      console.log('ClearInput', value)
      const { payload: key } = value
      const paginated = current(state[key])
      state[key] = {
        ...state[key],
        inputVariables: {},
        currentPage: 1,
        odataQuery: {
          ...state[key].odataQuery,
          skip: state[key].odataQuery.top * (1 - 1),
          filter: { ...paginated.filter },
        },
      }
    },

    UpdateContract: (state, value) => {},

    UpdateMessage: (state, value) => {
      //TODO add Update to message on Contract
      let targetContract = state.ObservingContracts.find((x) => x.id === value.payload.contractId)

      if (targetContract === undefined) return
      const filteredContracts = state.ObservingContracts.filter(
        (x) => x.id !== value.payload.contractId,
      )

      targetContract.messages = [...targetContract.messages, value.payload]

      state.ObservingContracts = [...filteredContracts, targetContract]
    },
  },
  extraReducers: {
    [GetCompletedContractsOdata.pending]: (state) => {
      state.paginatedCompleted.isLoading = true
    },
    [GetCompletedContractsOdata.fulfilled]: (state, action) => {
      state.paginatedCompleted.pageCount = action.payload['@odata.count']
      state.paginatedCompleted.items = action.payload.value
      state.paginatedCompleted.isLoading = false
    },
    [GetCompletedContractsOdata.rejected]: (state, action) => {
      state.paginatedCompleted.isLoading = false
    },

    [GetFailedContractsOdata.pending]: (state) => {
      state.paginatedFailed.isLoading = true
    },
    [GetFailedContractsOdata.fulfilled]: (state, action) => {
      state.paginatedFailed.pageCount = action.payload['@odata.count']
      state.paginatedFailed.items = action.payload.value
      state.paginatedFailed.isLoading = false
    },
    [GetFailedContractsOdata.rejected]: (state, action) => {
      state.paginatedFailed.isLoading = false
    },

    [GetLatestUserContractsOdata.pending]: (state) => {
      state.paginationCurrentUser.isLoading = true
    },
    [GetLatestUserContractsOdata.fulfilled]: (state, action) => {
      state.paginationCurrentUser.pageCount = action.payload['@odata.count']
      state.paginationCurrentUser.items = action.payload.value
      state.paginationCurrentUser.isLoading = false
    },
    [GetLatestUserContractsOdata.rejected]: (state, action) => {
      state.paginationCurrentUser.isLoading = false
    },

    [GetContractStatsOdata.pending]: (state) => {
      state.isLoading = true
    },
    [GetContractStatsOdata.fulfilled]: (state, action) => {
      var totalContracts = _.sumBy(action.payload, 'count')
      let Failed = _.sumBy(
        _.filter(
          action.payload,
          (x) =>
            x.contractStatusType === 0 ||
            x.contractStatusType === 10 ||
            x.contractStatusType === 15 ||
            x.contractStatusType === 20,
        ),
        'count',
      )
      let Completed = _.sumBy(
        _.filter(
          action.payload,
          (x) =>
            x.contractStatusType === 30 ||
            x.contractStatusType === 40 ||
            x.contractStatusType === 60 ||
            x.contractStatusType === 70 ||
            x.contractStatusType === -1,
        ),
        'count',
      )

      let Anonymized = _.sumBy(
        _.filter(action.payload, (x) => x.contractStatusType === 50),
        'count',
      )
      state.Stats = { totalContracts, Failed, Completed, Anonymized }
    },
    [GetContractStatsOdata.rejected]: (state, action) => {
      console.log('rejected', action)
    },

    [GetUserContractStatsOdata.pending]: (state) => {
      state.isLoading = true
    },
    [GetUserContractStatsOdata.fulfilled]: (state, action) => {
      state.UserFinishedContractsCount = action.payload
    },
    [GetUserContractStatsOdata.rejected]: (state, action) => {
      console.log('rejected', action)
    },

    [CreateContract.pending]: (state) => {
      state.isLoading = true
    },
    [CreateContract.fulfilled]: (state, action) => {
      console.log(JSON.stringify(action.payload))
    },
    [CreateContract.rejected]: (state, action) => {},

    [RejectContract.pending]: (state) => {
      state.isLoading = true
    },
    [RejectContract.fulfilled]: (state, action) => {
      state.Failed = state.Failed.filter((x) => x.id !== action.payload.id)
      state.Completed = [...state.Completed, action.payload]
    },
    [RejectContract.rejected]: (state, action) => {},

    [CompleteContract.pending]: (state) => {
      state.isLoading = true
    },
    [CompleteContract.fulfilled]: (state, action) => {
      // state.Failed = state.Failed.filter((x) => x.id !== action.payload.id)
      // state.Completed = [...state.Completed, action.payload]
    },
    [CompleteContract.rejected]: (state, action) => {},

    [GetBookmarks.pending]: (state) => {
      state.isLoading = true
    },
    [GetBookmarks.fulfilled]: (state, action) => {
      state.bookmarks = action.payload.bookmarks
      state.BookmarkedContracts = action.payload.BookmarkedContracts
    },
    [GetBookmarks.rejected]: (state, action) => {},

    [BookmarkToggle.pending]: (state) => {
      state.isLoading = true
    },
    [BookmarkToggle.fulfilled]: (state, action) => {
      state.bookmarks = action.payload.bookmarks
      state.BookmarkedContracts = action.payload.BookmarkedContracts
    },
    [BookmarkToggle.rejected]: (state, action) => {},
  },
})

export const {
  UpdateStates,
  UpdateFilter,
  UpdateInput,
  UpdateActivePage,
  UpdatePageItemsCount,
  UpdateColumnSort,
  ClearInput,
  CloseContract,
} = UserSlice.actions

export default UserSlice.reducer

export const State = {
  play: 1,
  stop: 2,
  //   pause: 3,
}

export const ServiceStatus = {
  done: 0,
  working: 1,
}
