import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

import { withCallbacks, signalMiddleware } from 'redux-signalr'
import { Connected, ConnectionLost, Disconnected, RenewLogin } from 'src/Features/Login/loginState'
import { GetLoggedInUser } from 'src/Features/Users/UsersState'
import store from 'src/store'

const connection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(window.extended.MYAPP_SIGNALR_URL + `/FrontOfficeHub`, {
    // skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .build()

export default connection

export let ConnectionId = null

const callbacks = withCallbacks()
  .add('connected', (msg) => (dispatch) => {
    console.signalR('ConnectionId', msg)
    ConnectionId = msg
  })
  .add('UrlVisitors', (msg) => (dispatch) => {
    dispatch({ type: 'Users/UsersOnURl', payload: msg })
  })
  .add('middelwareObj', (msg) => (dispatch) => {
    console.signalR('MSG', msg)
    dispatch({ type: msg.action, payload: msg.payload })
  })
  .add('renewLogin', (msg) => (dispatch) => {
    console.signalR('renewLogin')
    dispatch(RenewLogin({ refreshToken: store.getState().Login.JWT.refreshToken.token }))
    dispatch(GetLoggedInUser())
  })

connection.onreconnecting(async () => {
  store.dispatch(Disconnected())
})

connection.onreconnected(async () => {
  console.signalR('Reconnected')
  store.dispatch(Connected())
})

connection.onclose(async () => {
  store.dispatch(ConnectionLost())
  console.signalR('Closed!')
})

export const signal = signalMiddleware({
  callbacks,
  connection,
  shouldConnectionStartImmediately: false,
})
