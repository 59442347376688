import { configureStore } from '@reduxjs/toolkit'
import LoginReducer from './Features/Login/loginState'
import LayoutReducer from './Features/Layout/layoutState'
import UserReducer from './Features/Users/UsersState'
import LogReducer from './Features/Log/log'
import ContractsReducer from './Features/Contracts/contractsState'
import TemplateDesignerReducer from './Features/TemplateDesigner/templateDesignerState'
import TemplatesReducer from './Features/TemplateDesigner/TemplatesState'
import ProductsReducer from './Features/Products/productState'
import NotificationsReducer from './Features/Notification/Notifications'
import ModulesReducer from './Features/Modules/Modules'
import SettingsReducer from './Features/Settings/SettingsState'
import MetricReducer from './Features/Metric/metricState'
import AdministrationReducer from './Features/ITL-Administration/AdministrationState'
import TicketsReducer from './Features/Tickets/ticketState'
import { signal } from './Utils/SignalR/SignalR'

const store = configureStore({
  reducer: {
    Login: LoginReducer,
    Metric: MetricReducer,
    Layout: LayoutReducer,
    Users: UserReducer,
    Logs: LogReducer,
    Contracts: ContractsReducer,
    Modules: ModulesReducer,
    Notifications: NotificationsReducer,
    Products: ProductsReducer,
    TemplateDesigner: TemplateDesignerReducer,
    Setting: SettingsReducer,
    Templates: TemplatesReducer,
    Administration: AdministrationReducer,
    Tickets: TicketsReducer,
  },
  middleware: [signal],
}) //createStore(changeState)
export default store
