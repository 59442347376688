import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BuildHeader } from 'src/Utils/headerBuilder'
import { RequestMethods } from '../Helper/FetchHelpers'

const initialState = () => {
  return {
    Wrappers: null,
    Emails: null,
  }
}

export const GetWrapper = createAsyncThunk('GetWrapper', async (Login, password) => {
  var raw = JSON.stringify(Login)

  var requestOptions = {
    method: 'GET',
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/' + 'GetWappers',
    requestOptions,
  )
  if (!response.ok) {
    const res = await response.text()
    throw new Error(res)
  }

  const formattedResponse = await response.json()
  return formattedResponse
})

export const UpdateWrapper = createAsyncThunk('UpdateWrapper', async (data) => {
  var raw = JSON.stringify(data)
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/UpdateTemplateWrapper',
    requestOptions,
  )

  if (!response.ok) {
    const res = await response.json()
    console.log('res', res)
    throw new Error(res)
  }

  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const GetEmailWrapper = createAsyncThunk('GetEmailWrapper', async (Login, password) => {
  var raw = JSON.stringify(Login)

  var requestOptions = {
    method: 'GET',
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/' + 'GetEmails',
    requestOptions,
  )
  if (!response.ok) {
    const res = await response.text()
    throw new Error(res)
  }

  const formattedResponse = await response.json()
  return formattedResponse
})

export const UpdateEmailTemplate = createAsyncThunk('UpdateEmailTemplate', async (data) => {
  var raw = JSON.stringify(data)
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/UpdateEmailTemplate',
    requestOptions,
  )

  if (!response.ok) {
    const res = await response.json()
    console.log('res', res)
    throw new Error(res)
  }

  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const InsertEmailTemplate = createAsyncThunk('InsertEmailTemplate', async (data) => {
  var raw = JSON.stringify(data)
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Template/InsertEmailTemplate',
    requestOptions,
  )

  if (!response.ok) {
    const res = await response.json()
    console.log('res', res)
    throw new Error(res)
  }

  const formatttedResponse = await response.json()
  return formatttedResponse
})

export const SendEmailTemplate = createAsyncThunk('DeleteImage', async (templateGuid) => {
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/EmailTest/SendEmailWithTemplate?emailId=' + templateGuid,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

const TemplatesSlice = createSlice({
  name: 'Templates',
  initialState,
  reducers: {},
  extraReducers: {
    [GetWrapper.pending]: (state) => {
      state.isLoading = true
    },
    [GetWrapper.fulfilled]: (state, action) => {
      console.log('GetTemplates.fulfilled')
      state.Wrappers = action.payload
    },
    [GetWrapper.rejected]: (state, action) => {},

    [UpdateWrapper.pending]: (state) => {
      state.isLoading = true
    },
    [UpdateWrapper.fulfilled]: (state, action) => {
      console.log('GetTemplates.fulfilled')
      // state.Wrappers = action.payload
    },
    [UpdateWrapper.rejected]: (state, action) => {},

    [GetEmailWrapper.pending]: (state) => {
      state.isLoading = true
    },
    [GetEmailWrapper.fulfilled]: (state, action) => {
      console.log('GetEmailWrapper.fulfilled')
      state.Emails = action.payload
    },
    [GetEmailWrapper.rejected]: (state, action) => {},

    [InsertEmailTemplate.pending]: (state) => {
      state.isLoading = true
    },
    [InsertEmailTemplate.fulfilled]: (state, action) => {
      console.log('InsertEmailTemplate.fulfilled')
      state.Emails = [...state.Emails, action.payload]
    },
    [InsertEmailTemplate.rejected]: (state, action) => {},

    [UpdateEmailTemplate.pending]: (state) => {
      state.isLoading = true
    },
    [UpdateEmailTemplate.fulfilled]: (state, action) => {
      state.Emails = [...state.Emails.filter((x) => x.id !== action.payload.id), action.payload]
    },
    [UpdateEmailTemplate.rejected]: (state, action) => {},

    [SendEmailTemplate.pending]: (state) => {
      state.isLoading = true
    },
    [SendEmailTemplate.fulfilled]: (state, action) => {
      // state.Emails = action.payload
    },
    [SendEmailTemplate.rejected]: (state, action) => {},
  },
})

// export const { Logout, SetSessionTimeoutSecounds, Connected, Disconnected, ConnectionLost } =
//   TemplatesSlice.actions

export default TemplatesSlice.reducer
