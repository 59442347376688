import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { current } from 'immer'

import { AsyncOdataObjectQuery, AsyncQueryRequest } from 'src/Utils/headerBuilder'
import { BuildOdateFilter } from 'src/Utils/Odata/Filterbuilder'

const apiController = '/logger/'

const initialState = () => {
  return {
    Log: null,
    amountOfLogs: 0,

    paginatedUserLogs: {
      filter: [],
      odataQuery: {
        filter: [],
        orderBy: ['id desc'],
        count: true,
        top: 10,
        skip: 0,
      },
      inputVariables: {},
      currentPage: 1,
      isLoading: true,
      pageCount: 0,
      items: [],
    },
    systemLogStats: { isLoading: true },
    paginatedSystemLogs: {
      odataQuery: {
        orderBy: ['id desc'],
        count: true,
        top: 10,
        skip: 0,
      },
      inputVariables: {},
      currentPage: 1,
      isLoading: true,
      pageCount: 0,
      items: [],
    },
  }
}

const ErrorLogStatsQuery = {
  transform: {
    groupBy: {
      properties: ['level'],
      transform: {
        aggregate: {
          $count: {
            as: 'count',
          },
        },
      },
    },
  },
}

export const GetSystemLogStatsOdata = createAsyncThunk('GetSystemLogStatsOdata', async () =>
  AsyncOdataObjectQuery('/SystemLog', ErrorLogStatsQuery),
)

export const GetLogs = createAsyncThunk('GetLogs', async (query) =>
  AsyncQueryRequest(apiController + 'GetLogs', query),
)

export const GetSystemLog = createAsyncThunk('GetSystemLog', async (filter) =>
  AsyncOdataObjectQuery('/SystemLog', filter),
)

export const GetUserLog = createAsyncThunk('GetUserLog', async (filter) =>
  AsyncOdataObjectQuery('/UserLog', filter),
)

const LogSlice = createSlice({
  name: 'Logs',
  initialState,
  reducers: {
    UpdateFilter: (state, value) => {
      for (const [key, item] of Object.entries(value.payload)) {
        state[key] = {
          ...state[key],
          ...value.payload[key],
        }
      }
    },

    UpdateInput: (state, value) => {
      const { payload } = value
      const { key, input } = payload

      const paginated = current(state[key])

      const { inputVariables, parsedProps } = BuildOdateFilter(paginated, input)

      state[key] = {
        ...state[key],
        inputVariables,
        currentPage: 1,
        odataQuery: {
          ...state[key].odataQuery,
          skip: state[key].odataQuery.top * (1 - 1),
          filter: { ...parsedProps, ...paginated.filter },
        },
      }
    },

    UpdateActivePage: (state, value) => {
      const { payload } = value
      const { key, activePage } = payload

      state[key] = {
        ...state[key],
        currentPage: activePage,
        odataQuery: {
          ...state[key].odataQuery,
          skip: state[key].odataQuery.top * (activePage - 1),
        },
      }
    },

    UpdatePageItemsCount: (state, value) => {
      const { payload } = value
      const { key, pageItemsCount } = payload

      state[key] = {
        ...state[key],
        odataQuery: {
          ...state[key].odataQuery,
          top: pageItemsCount,
          skip: pageItemsCount * (state[key].currentPage - 1),
        },
      }
    },
    UpdateColumnSort: (state, value) => {
      const { payload } = value
      const { key, SortOrder } = payload

      state[key] = {
        ...state[key],
        odataQuery: {
          ...state[key].odataQuery,
          orderBy: [SortOrder],
        },
      }
    },
    ClearInput: (state, value) => {
      console.log('ClearInput', value)
      const { payload: key } = value
      const paginated = current(state[key])
      state[key] = {
        ...state[key],
        inputVariables: {},
        currentPage: 1,
        odataQuery: {
          ...state[key].odataQuery,
          skip: state[key].odataQuery.top * (1 - 1),
          filter: { ...paginated.filter },
        },
      }
    },
  },
  extraReducers: {
    [GetSystemLog.pending]: (state) => {
      state.paginatedSystemLogs.isLoading = true
    },
    [GetSystemLog.fulfilled]: (state, action) => {
      state.paginatedSystemLogs.pageCount = action.payload['@odata.count']
      state.paginatedSystemLogs.items = action.payload.value
      state.paginatedSystemLogs.isLoading = false
    },
    [GetSystemLog.rejected]: (state, action) => {
      state.paginatedUserLogs.isLoading = false
    },

    [GetUserLog.pending]: (state) => {
      state.paginatedUserLogs.isLoading = true
    },
    [GetUserLog.fulfilled]: (state, action) => {
      state.paginatedUserLogs.pageCount = action.payload['@odata.count']
      state.paginatedUserLogs.items = action.payload.value
      state.paginatedUserLogs.isLoading = false
    },
    [GetUserLog.rejected]: (state, action) => {
      state.paginatedUserLogs.isLoading = false
    },

    [GetSystemLogStatsOdata.pending]: (state) => {
      state.systemLogStats.isLoading = true
    },
    [GetSystemLogStatsOdata.fulfilled]: (state, action) => {
      // console.log(action.payload)

      const transformedData = action.payload.reduce((acc, { level, count }) => {
        acc[level] = count
        return acc
      }, {})

      state.systemLogStats = {
        totalItems: _.sumBy(action.payload, 'count'),
        ...transformedData,
      }
    },
    [GetSystemLogStatsOdata.rejected]: (state, action) => {
      state.systemLogStats.isLoading = false
    },
  },
})

export const {
  UpdateFilter,
  UpdateActivePage,
  UpdateColumnSort,
  UpdateInput,
  UpdatePageItemsCount,
} = LogSlice.actions

export default LogSlice.reducer
