import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { AsyncFormRequest, AsyncQueryRequest, BuildHeader } from 'src/Utils/headerBuilder'

import { RequestMethods } from '../Helper/FetchHelpers'

const apiController = '/Product/'

const initialState = () => {
  return {
    Products: [],
  }
}

export const InsertProduct = createAsyncThunk('InsertProduct', async (data) =>
  AsyncFormRequest(apiController + 'insert', data, RequestMethods.POST),
)

export const UpdateProduct = createAsyncThunk('UpdateProduct', async (data) =>
  AsyncFormRequest(apiController + 'update', data, RequestMethods.PUT),
)

export const DeleteProduct = createAsyncThunk('DeleteProduct', async (query) =>
  AsyncQueryRequest(apiController + 'delete', query, RequestMethods.DELETE),
)

export const GetProducts = createAsyncThunk('GetProducts', async () =>
  AsyncFormRequest(apiController + 'getSummaries'),
)

export const DeleteImage = createAsyncThunk('DeleteImage', async (id) => {
  var requestOptions = {
    method: RequestMethods.DELETE,
    headers: BuildHeader(),
    // redirect: "follow",
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/Image/delete?id=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse
})

const LogSlice = createSlice({
  name: 'Logs',
  initialState,
  extraReducers: {
    [GetProducts.pending]: (state) => {
      state.isLoading = true
    },
    [GetProducts.fulfilled]: (state, action) => {
      state.Products = _.orderBy(action.payload, ['sortOrder'], ['asc'])
    },
    [GetProducts.rejected]: (state, action) => {},

    [InsertProduct.pending]: (state) => {
      state.isLoading = true
    },
    [InsertProduct.fulfilled]: (state, action) => {
      state.Products = _.orderBy([...state.Products, action.payload], ['sortOrder'], ['asc'])
    },
    [InsertProduct.rejected]: (state, action) => {},

    [UpdateProduct.pending]: (state) => {
      state.isLoading = true
    },
    [UpdateProduct.fulfilled]: (state, action) => {
      state.Products = _.orderBy(
        [...state.Products.filter((x) => x.id !== action.payload.id), action.payload],
        ['id'],
        ['asc'],
      )
    },
    [UpdateProduct.rejected]: (state, action) => {},

    [DeleteProduct.pending]: (state) => {
      state.isLoading = true
    },
    [DeleteProduct.fulfilled]: (state, action) => {
      state.Products = state.Products.filter((x) => x.id !== action.payload)
    },
    [DeleteProduct.rejected]: (state, action) => {},
  },
})

// export const { ToggleAside, ToggleDarkMode, Sidebar } = UserSlice.actions;

export default LogSlice.reducer
