import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { BuildHeader } from 'src/Utils/headerBuilder'

import CreateRequest, { RequestMethods } from '../Helper/FetchHelpers'

const initialState = () => {
  return {
    ReplacementTokens: [],
    SiteSettings: {},
    SmtpSettings: [],
    PropsForContractExtended: [],
  }
}

export const GetSystemSettings = createAsyncThunk('GetSystemSettings', async (id) => {
  // var raw = JSON.stringify(user)
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    //   body: raw,
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/ITL_Settings/System/get?Id=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const UpdateSystemSettings = createAsyncThunk('UpdateSystemSettings', async (data) => {
  var raw = JSON.stringify(data)
  var requestOptions = {
    method: RequestMethods.PUT,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/ITL_Settings/System/update',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const GetStmpSettings = createAsyncThunk('GetStmpSettings', async (id) => {
  // var raw = JSON.stringify(user)
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    //   body: raw,
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/ITL_Settings/Smtp/get?Id=' + id,
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const InsertStmpSettings = createAsyncThunk('InsertStmpSettings', async (data) => {
  var raw = JSON.stringify(data)
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/ITL_Settings/Smtp/insert',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const UpdateStmpSettings = createAsyncThunk('UpdateStmpSettings', async (data) => {
  var raw = JSON.stringify(data)
  var requestOptions = {
    method: RequestMethods.PUT,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/ITL_Settings/Smtp/update',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const SendTestMail = createAsyncThunk('SendTestMail', async (data) => {
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT +
      `/ITL_Settings/sendTestMail?to=${data.to}&toMail=${data.toMail}&subject=${data.subject}&body=${data.body}`,
    requestOptions,
  )

  if (!response.ok) {
    const res = await response.text()
    throw new Error(res)
  }

  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const GetReplacmentTokens = createAsyncThunk('GetReplacmentTokens', async () => {
  var requestOptions = {
    method: RequestMethods.GET,
    headers: BuildHeader(),
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/ContractTemplate/replacementTokens',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const GetPropsForContractExtended = createAsyncThunk(
  'GetPropsForContractExtended',
  async () => {
    var requestOptions = {
      method: RequestMethods.GET,
      headers: BuildHeader(),
      // redirect: "follow",
    }
    const response = await fetch(
      window.extended.MYAPP_API_ENDPOINT +
        '/ITL_Settings/ReplacementTokens/getPropsForContractExtended',
      requestOptions,
    )
    const formatttedResponse = await response.json()
    return formatttedResponse.object
  },
)

export const UpdateReplaceToken = createAsyncThunk('UpdateReplaceToken', async (data) => {
  var raw = JSON.stringify(data)
  var requestOptions = {
    method: RequestMethods.PUT,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/ITL_Settings/ReplacementToken/update',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

export const CreateReplaceToken = createAsyncThunk('CreateReplaceToken', async (data) => {
  var raw = JSON.stringify(data)
  var requestOptions = {
    method: RequestMethods.POST,
    headers: BuildHeader(),
    body: raw,
    // redirect: "follow",
  }
  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + '/ITL_Settings/ReplacementToken/insert',
    requestOptions,
  )
  const formatttedResponse = await response.json()
  return formatttedResponse.object
})

const SettingsSlice = createSlice({
  name: 'Settings',
  initialState,
  extraReducers: {
    [GetSystemSettings.pending]: (state) => {
      state.isLoading = true
    },
    [GetSystemSettings.fulfilled]: (state, action) => {
      state.SiteSettings = action.payload
    },
    [GetSystemSettings.rejected]: (state, action) => {},

    [UpdateSystemSettings.pending]: (state) => {
      state.isLoading = true
    },
    [UpdateSystemSettings.fulfilled]: (state, action) => {
      state.SiteSettings = action.payload
    },
    [UpdateSystemSettings.rejected]: (state, action) => {},

    [GetReplacmentTokens.pending]: (state) => {
      state.isLoading = true
    },
    [GetReplacmentTokens.fulfilled]: (state, action) => {
      state.ReplacementTokens = action.payload
    },
    [GetReplacmentTokens.rejected]: (state, action) => {},

    [GetPropsForContractExtended.pending]: (state) => {
      state.isLoading = true
    },
    [GetPropsForContractExtended.fulfilled]: (state, action) => {
      state.PropsForContractExtended = action.payload
    },
    [GetPropsForContractExtended.rejected]: (state, action) => {},

    [UpdateReplaceToken.pending]: (state) => {
      state.isLoading = true
    },
    [UpdateReplaceToken.fulfilled]: (state, action) => {
      state.ReplacementTokens = _.orderBy(
        [...state.ReplacementTokens.filter((x) => x.id !== action.payload.id), action.payload],
        ['id'],
        ['asc'],
      )
    },
    [UpdateReplaceToken.rejected]: (state, action) => {},

    [CreateReplaceToken.pending]: (state) => {
      state.isLoading = true
    },
    [CreateReplaceToken.fulfilled]: (state, action) => {
      state.ReplacementTokens = _.orderBy(
        [...state.ReplacementTokens.filter((x) => x.id !== action.payload.id), action.payload],
        ['id'],
        ['asc'],
      )
    },
    [CreateReplaceToken.rejected]: (state, action) => {},
  },
})

// export const { ToggleAside, ToggleDarkMode, Sidebar } = UserSlice.actions;

export default SettingsSlice.reducer
