import store from 'src/store'
import buildQuery from 'odata-query'
import { ConnectionId } from './SignalR/SignalR'
import { RequestMethods } from 'src/Features/Helper/FetchHelpers'

export const BuildHeader = () => {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  if (ConnectionId !== null) {
    myHeaders.append('x-signalr-id', ConnectionId)
    myHeaders.append('x-page', window.location.pathname)
  }
  if (store.getState().Login.JWT !== null) {
    myHeaders.append('Authorization', `Bearer ${store.getState().Login.JWT.token}`)
  }

  return myHeaders
}

export const BuildFormHeader = () => {
  var myHeaders = new Headers()
  //if Content-Type is defined it breaks (boltcheck)
  // myHeaders.append('Content-Type', 'multipart/form-data')
  if (ConnectionId !== null) {
    myHeaders.append('x-signalr-id', ConnectionId)
    myHeaders.append('x-page', window.location.pathname)
  }
  if (store.getState().Login.JWT !== null) {
    myHeaders.append('Authorization', `Bearer ${store.getState().Login.JWT.token}`)
  }

  return myHeaders
}

export const AsyncFormRequest = async (endpoint, body, requestMethods = RequestMethods.GET) => {
  var requestOptions = {
    method: requestMethods,
    headers: BuildHeader(),
    body: JSON.stringify(body),
  }

  const response = await fetch(window.extended.MYAPP_API_ENDPOINT + endpoint, requestOptions)
  if (!response.ok) {
    const res = await response.text()
    throw new Error(res)
  }

  const formattedResponse = await response.json()
  return formattedResponse
}

// Query is created for eeach Key value pair { Id: extended.id } = ?Id=extended.id
export const AsyncQueryRequest = async (endpoint, query, requestMethods = RequestMethods.GET) => {
  var requestOptions = {
    method: requestMethods,
    headers: BuildHeader(),
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + endpoint + BuildQueryFromObject(query),
    requestOptions,
  )
  if (!response.ok) {
    const res = await response.text()
    throw new Error(res)
  }

  const formattedResponse = await response.json()
  return formattedResponse
}

export const AsyncQueryRequestTextResult = async (
  endpoint,
  query,
  requestMethods = RequestMethods.GET,
) => {
  var requestOptions = {
    method: requestMethods,
    headers: BuildHeader(),
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + endpoint + BuildQueryFromObject(query),
    requestOptions,
  )
  if (!response.ok) {
    const res = await response.text()
    throw new Error(res)
  }

  const formattedResponse = await response.text()
  return formattedResponse
}

//Help is here https://github.com/techniq/odata-query
export const AsyncOdataObjectQuery = async (
  endpoint,
  odataObject,
  requestMethods = RequestMethods.GET,
) => {
  // console.log('AsyncOdataObjectQuery', odataObject)
  var Query = buildQuery(odataObject)
  // console.log(Query)
  var requestOptions = {
    method: requestMethods,
    headers: BuildHeader(),
  }

  const response = await fetch(
    window.extended.MYAPP_API_ENDPOINT + endpoint + Query,
    requestOptions,
  )
  if (!response.ok) {
    const res = await response.text()
    throw new Error(res)
  }

  const formattedResponse = await response.json()
  return formattedResponse
}

const BuildQueryFromObject = (queryObject) => {
  let BuildQueryParameters = '?'

  for (const [key, value] of Object.entries(queryObject)) {
    //console.log(`${key} : ${value}`)
    BuildQueryParameters += `${key}=${encodeURIComponent(value)}&`
  }
  //Remove last & from query
  BuildQueryParameters = BuildQueryParameters.slice(0, -1)

  return BuildQueryParameters
}
